
import React, { useEffect, useState } from 'react';
import bg from '../assets/images/bg.jpg';
// import google from './assets/images/google.svg';
import logo from '../assets/images/logo.svg';
import { useGlobalContext } from '../context/ContextProvider';
// import video from '../assets/videos/video.mp4';
import { Inputs256 } from "../components/Inputs256";
import { useNavigate } from "react-router-dom";
const DATE_OF_BARTH = {
  DD: Array.from({ length: 31 }, (v, i) => 1 + i),
  MM: Array.from({ length: 12 }, (v, i) => 1 + i),
  YYYY: Array.from({ length: 2024 - 1950 + 1 }, (v, i) => 2024 - i)
}
export default function Signin() {
  const context = useGlobalContext();
  const navigate = useNavigate()
  const [isLogin, setLogin] = useState(true);
  const [getEmail, setEmail] = useState(process.env.NODE_ENV === "development" ? "music.alsheikhaminulislam@gmail.com" : "");
  const [getPaswword, setPaswword] = useState(process.env.NODE_ENV === "development" ? "12345678" : "");
  const [getRePaswword, setRePaswword] = useState(process.env.NODE_ENV === "development" ? "12345678" : "");
  const [isEmailVerification, setEmailVerification] = useState(false);
  const [getEmailOtp, setEmailOtp] = useState("");
  const [getPositionSignUp, setPositionSignUp] = useState(0);
  const [getFirstName, setFirstName] = useState(process.env.NODE_ENV === "development" ? "fn" : "");
  const [getLastName, setLastName] = useState(process.env.NODE_ENV === "development" ? "ln" : "");
  const [getSelectDD, setSelectDD] = useState(process.env.NODE_ENV === "development" ? 10 : 0);
  const [getSelectMM, setSelectMM] = useState(process.env.NODE_ENV === "development" ? 10 : 0);
  const [getSelectYYYY, setSelectYYYY] = useState(process.env.NODE_ENV === "development" ? 2007: 0);
  const [error, seterror] = useState("");

  const [getGender, setGender] = useState("");
  const [getAccountType, setAccountType] = useState("");

  function SignInSignUp() {
    if (isLogin) {
      if (getEmail === "") {
        seterror("Invlaid email address.");
        return;
      }
      if (getPaswword === "") {
        seterror("Invlaid password.");
        return;
      }
      seterror("");
      setTimeout(()=>{
        context.https.postCredentials("signinToAccount",
          {
            cmail: getEmail,
            password: getPaswword,
          }
        ).then(() => {
          // console.log(context.https.data); 
          if (context.https.data.success) {
            navigate("/dashboard/welcome")
          }else{
            seterror(context.https.data.ms);}
        })
      },1000)
    } else {
      if (getPositionSignUp === 1) {
        if (getEmail === "") {
          seterror("Invlaid email address.");
          return;
        }
        if (getPaswword === "") {
          seterror("Invlaid password.");
          return;
        }
        if (getRePaswword === "") {
          seterror("Invlaid re-password.");
          return;
        }
        if (getPaswword !== getRePaswword) {
          seterror("Password are not match.");
          return;
        }
        seterror("");
        setPositionSignUp(2);
      } else if (getPositionSignUp === 2) {
        if (getFirstName === "") {
          seterror("Invlaid first name.");
          return;
        }
        if (getLastName === "") {
          seterror("Invlaid last name.");
          return;
        }
        seterror("");
        setPositionSignUp(3);
      } else if (getPositionSignUp === 3) {
        if (getSelectDD === 0) {
          seterror("Invlaid DD.");
          return;
        }
        if (getSelectMM === 0) {
          seterror("Invlaid MM.");
          return;
        }
        if (getSelectYYYY === 0) {
          seterror("Invlaid YYYY.");
          return;
        }
        if (getGender === "") {
          seterror("Invlaid gender.");
          return;
        }
        if (getAccountType === "") {
          seterror("Invlaid account type.");
          return;
        }
        seterror("");
        sendOtp()
        setPositionSignUp(4);
      } else if (getPositionSignUp === 4) {
        setTimeout(()=>{
          context.https.postCredentials("createNewAccount",
            {
              verifyCode: getEmailOtp,
              cmail: getEmail,
              username: getEmail.split("@")[0],
              fname: getFirstName,
              lname: getLastName,
              cday: getSelectDD,
              cmonth: getSelectMM,
              cyear: getSelectYYYY,
              password: getPaswword,
              rpassword: getRePaswword,
              cgender: getGender,
              ctype: getAccountType,
            }
          ).then(() => {
            // console.log(context.https.data); 
            if (context.https.data.success) {
              navigate("/dashboard/welcome")
            }
          })
        },1000)
      }
    }
  }
  function sendOtp() {
    context.https.postCredentials("sendAuthCode", { mail: getEmail }
    ).then(() => {
      if (context.https.data.success) {
        setEmailVerification(true)
      } else {
        setPositionSignUp(1);
        seterror(context.https.data.error);
      }
    })
  }
  useEffect(() => {
    context.https.postCredentials("authorizationToken", {})
      .then(() => {
        if (context.https.data.success) {
          navigate("/dashboard/welcome")
        }
      })
  }, [])

  return (
    <div className="2xl:container h-screen m-auto">
      <div className="hidden fixed inset-0 w-7/12 lg:block">
        {/* <span className="absolute left-6 bottom-6 text-sm">Video by MART PRODUCTION from
          <a href="https://www.pexels.com/" target="blank" title="Pexels">Pexels</a>
        </span> */}
        <video className="w-full h-full object-cover" loop autoPlay poster={bg} >
          {/* <source src={video} type="video/mp4" /> */}
        </video>
      </div>
      <div className="hidden fixed inset-0 w-6/12 ml-auto bg-white bg-opacity-70 backdrop-blur-xl lg:block"></div>
      <div className="relative h-full ml-auto lg:w-6/12">
        <div className="m-auto py-12 px-6 sm:p-20 xl:w-10/12">
          <div className="space-y-4">
            <img src={logo} className="w-40" alt="ValoXity logo" />
            <p className="font-medium text-lg text-gray-600">Welcome to ValoXity ! Login first</p>
          </div>


          {/* <div className="mt-12 grid gap-6 sm:grid-cols-2">
              <button className="py-3 px-6 rounded-xl bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 active:bg-blue-200">
                <div className="flex gap-4 justify-center">
                  <img src={google} className="w-5" alt="" />
                  <span className="block w-max font-medium tracking-wide text-sm text-blue-700">with  Google</span>
                </div>
              </button>
              <button className="py-3 px-6 rounded-xl bg-gray-900 transition hover:bg-gray-800 active:bg-gray-600 focus:bg-gray-700">
                <div className="flex gap-4 items-center justify-center text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5" viewBox="0 0 16 16">
                    <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                  </svg>
                  <span className="block w-max font-medium tracking-wide text-sm text-white">with Github</span>
                </div>
              </button>
            </div>
  
            <div className="hidden mt-12 border-t">
              <span className="block w-max mx-auto -mt-3 px-4 text-center text-gray-500 bg-white">Or</span>
            </div> */}

          <div className="space-y-6 py-6">

            <p className="font-medium text-sm w-full text-center text-red-600">{error}</p>
            {isLogin ?
              <Inputs256
                type="email" placeholder="Email"
                get={getEmail} set={setEmail} readOnly={false} /> :
              getPositionSignUp === 1 ?
                <Inputs256
                  type="email" placeholder="Email"
                  get={getEmail} set={setEmail} readOnly={false} /> : null}

            {isLogin ?
              <Inputs256
                type="password" placeholder="Password"
                get={getPaswword} set={setPaswword} readOnly={false} /> :
              getPositionSignUp === 1 ?
                <Inputs256
                  type="password" placeholder="Password"
                  get={getPaswword} set={setPaswword} readOnly={false} /> : null}

            {!isLogin && getPositionSignUp === 1 && (<Inputs256
              type="password" placeholder="Re-Password"
              get={getRePaswword} set={setRePaswword} readOnly={false} />)}



            {!isLogin && getPositionSignUp === 2 && (
              <Inputs256
                type="text" placeholder="First Name"
                get={getFirstName} set={setFirstName} readOnly={false} />
            )}
            {!isLogin && getPositionSignUp === 2 && (
              <Inputs256
                type="text" placeholder="Last Name"
                get={getLastName} set={setLastName} readOnly={false} />
            )}
            {!isLogin && getPositionSignUp === 2 && (
              <Inputs256
                type="text" placeholder="Username"
                get={getEmail.split("@")[0]} set={setEmail} readOnly={true} />
            )}


            {!isLogin && isEmailVerification && getPositionSignUp === 4 ?
              <Inputs256
                type="text" placeholder="OTP"
                get={getEmailOtp} set={setEmailOtp} readOnly={false} /> : null}

            {!isLogin && getPositionSignUp === 3 && (<div className="flex  items-center ">
              <select className="select select-bordered w-full max-w-xs mx-1"
                onChange={(e) => setSelectDD(Number(e.target.value))} >
                <option disabled selected>DD</option>
                {DATE_OF_BARTH.DD.map((data, index) => <option key={index} value={data} >{data}</option>)}
              </select>
              <select className="select select-bordered w-full max-w-xs mx-1"
                onChange={(e) => setSelectMM(Number(e.target.value))} >
                <option disabled selected>MM</option>
                {DATE_OF_BARTH.MM.map((data, index) => <option key={index} value={data} >{data}</option>)}
              </select>
              <select className="select select-bordered w-full max-w-xs mx-1"
                onChange={(e) => setSelectYYYY(Number(e.target.value))} >
                <option disabled selected>YYYY</option>
                {DATE_OF_BARTH.YYYY.map((data, index) => <option key={index} value={data} >{data}</option>)}
              </select>
            </div>)}

            {!isLogin && getPositionSignUp === 3 && (<div className="flex  items-center ">
              <select className="select select-bordered w-full max-w-xs mx-1"
                onChange={(e) => setGender(e.target.value)} >
                <option disabled selected>Gender</option>
                <option value={"male"}>Male</option>
                <option value={"female"}>Females </option>
                <option value={"androgyne"}>Androgynes</option>
              </select>
              <select className="select select-bordered w-full max-w-xs mx-1"
                onChange={(e) => setAccountType(e.target.value)} >
                <option disabled selected>Account Type</option>
                <option value={"Personal"}>Personal</option>
                <option value={"business"}>business</option>
              </select>
            </div>)}
            {/* <div className="flex flex-col items-end">
                <input
                  type="password"
                  placeholder="What's the secret word ?"
                  className="w-full py-3 px-6 ring-1 ring-gray-300 rounded-xl placeholder-gray-600 bg-transparent transition disabled:ring-gray-200 disabled:bg-gray-100 disabled:placeholder-gray-400 invalid:ring-red-400 focus:invalid:outline-none"
                />
                <button type="reset" className="w-max p-3 -mr-3">
                  <span className="text-sm tracking-wide text-blue-600">Forgot password ?</span>
                </button>
              </div> */}

            <div>
              <button
                onClick={() => SignInSignUp()}
                className="w-full px-6 py-3 rounded-xl bg-sky-500 transition hover:bg-sky-600 focus:bg-sky-600 active:bg-sky-800">
                <span className="font-semibold text-white text-lg">{isLogin ? "SignIn" : "SignUp"}</span>
              </button>
              <div className="w-max p-3 -ml-3 cursor-pointer" onClick={() => { setPositionSignUp(1); setLogin(!isLogin); }}>
                <span className="text-sm tracking-wide text-blue-600">{isLogin ? "Create new account" : "I have an account"}</span>
              </div>
            </div>
          </div>

          <div className="border-t pt-12">
            <div className="space-y-2 text-center">
              <img src={logo} className="w-40 m-auto grayscale" alt="" />
              <span className="block text-sm tracking-wide text-gray-500">ValoXity offers new refill facility every month free.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}