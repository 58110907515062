import axios from 'axios'; 

class https {
  constructor(url) { 
    this.url = url;
    this.loading = false;
    this.error = null;
    this.data = null;
    this.config = {
      // timeout: 10000, // 10 seconds timeout
      withCredentials: true,
      headers: { 
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
      }
    };
  }

  async get(action) {
    this.loading = true;
    try { 
      const response = await axios.get(`${this.url}/${action}`, this.config);
      this.data = response.data;
    } catch (error) {
      this.error = error;
      console.error('An error occurred during the GET request:', error);
    } finally {
      this.loading = false;
    }
  }

  async getCredentials(queryString) {
    this.loading = true;
    try { 
      const response = await axios.get(`${this.url}/${queryString}`, this.config);
      this.data = response.data;
    } catch (error) {
      this.error = error;
      console.error('An error occurred during the GET request with credentials:', error);
    } finally {
      this.loading = false;
    }
  }

  async post(action, dataToSend) {
    this.loading = true;
    try {
      const response = await axios.post(`${this.url}/${action}`, dataToSend, this.config);
      this.data = response.data;
    } catch (error) {
      this.error = error;
      console.error('An error occurred during the POST request:', error);
    } finally {
      this.loading = false;
    }
  }

  async postCredentials(action, dataToSend) {
    this.loading = true;
    try {
      const response = await axios.post(`${this.url}/${action}`, dataToSend, this.config);
      this.data = response.data;
      return this.data;
    } catch (error) {
      this.error = error;
      console.error('An error occurred during the POST request with credentials:', error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  formatTime(seconds) {
    const days = Math.floor(seconds / (60 * 60 * 24));
    const hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const remainingSeconds = seconds % 60;

    let result = '';
    if (days > 0) {
      result += `${days} day${days === 1 ? '' : 's'} `;
    }
    if (hours > 0) {
      result += `${hours} hour${hours === 1 ? '' : 's'} `;
    }
    if (minutes > 0) {
      result += `${minutes} minute${minutes === 1 ? '' : 's'} `;
    }
    if (remainingSeconds > 0) {
      result += `${remainingSeconds} second${remainingSeconds === 1 ? '' : 's'}`;
    }
    return result.trim();
  }
}

export default https;