import { FaPlus } from "react-icons/fa";
import { CiMenuKebab } from "react-icons/ci";
import Navigation from "../components/Navigation"
import { useEffect, useRef, useState } from "react";
import { useGlobalContext } from "../context/ContextProvider";

export default function Apikeys() {
  const context = useGlobalContext();
  const [isOpendialog, setIsOpendialog] = useState<boolean>(false);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [getCredentialsList, setCredentialsList] = useState<Credential[]>([]);
  interface Credential {
    name: string;
    key: string;
    createdate: string;
    restrictions: string;
    isdelete: string;
  }
  const [getKey, setkey] = useState("");
  function showKey(key: string) {
    setkey(key)
    openModal()
  }
  const openModal = () => {
    setIsOpendialog(true);
    document.body.classList.add('modal-open');
  };

  const closeModal = () => {
    setIsOpendialog(false);
    document.body.classList.remove('modal-open');
  };
  function createCredentials() {
    setIsOpenDialog(true);
    setTimeout(() => {
      context.https.postCredentials("createCredentials")
        .then(() => {
          if (context.https.data.success) {
            setIsOpenDialog(false);
            getCredentials();
          }
        })
    }, 1000)
  }
  function getCredentials() {
    setTimeout(() => {
      context.https.getCredentials("getCredentials")
        .then(() => {
          if (context.https.data.success) {
            setCredentialsList(context.https.data.credentials)
          }
        })
    }, 1000)
  }
  function copyKey(params: any) {
    setIsOpendialog(false)
    navigator.clipboard.writeText(params)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  }
  useEffect(() => {
    getCredentials()
  }, [])
  return (
    <main className="w-full h-screen flex flex-row relative">
      <Navigation />
      <section className="flex flex-col p-10 ml-20 w-full gap-5">
        <div className="flex">
          {/* <h4 className="text-2xl text-neutral-200 mr-3">Credentials </h4> */}
          <div className="flex p-1 
              rounded-md flex 
              justify-center item-center 
              cursor-pointer ">
            <FaPlus className="text-sm h-full mx-1" />
            <div className="dropdown">
              <div tabIndex={0} role="button" className=" m-1"> Create credentials</div>
              <ul tabIndex={0}
                className="dropdown-content menu   bg-base-100 rounded-box z-[1] w-96 p-2 shadow">
                <li onClick={() => createCredentials()}>
                  <div className="block" >
                    <p>API key</p>
                    <p className="text-xs w-full whitespace-normal" >
                      Identifies your project using a simple API key to check quota and access
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <div className="flex flex-row gap-5 w-full">
          <div className="border-neutral-500/50 h-60 w-1/2 bg-neutral-800/20 rounded border" />
          <div className="border-neutral-500/50 h-60 w-1/2 bg-neutral-800/20 rounded border" />
        </div> */}
        <div className="w-full  border border-neutral-500/50 bg-neutral-800/20 rounded" >
          <div className="overflow-x-auto">
            <table className="table table-zebra">
              <thead>
                <tr>
                  <th> <div className="flex justify-start">Name</div> </th>
                  <th> <div className="flex justify-center"> Creation date</div></th>
                  <th> <div className="flex justify-center">Restrictions</div> </th>
                  <th> <div className="flex justify-end">Actions</div> </th>
                </tr>
              </thead>
              <tbody>
                {getCredentialsList.map((data: Credential, index: number) => {
                  return (<tr key={index}>
                    <td > <div className="flex justify-start text-xs">{data.name}</div></td>
                    <td > <div className="flex justify-center text-xs">{data.createdate}</div></td>
                    <td > <div className="flex justify-center text-xs">{data.restrictions}</div></td>
                    <td >
                      <div className="flex cursor-pointer justify-end link link-primary" onClick={() => showKey(data.key)}>SHOW KEY
                        <CiMenuKebab className="text-lg" />
                      </div>
                    </td>
                  </tr>)
                })}

              </tbody>
            </table>
          </div>
        </div>
      </section>

      {isOpendialog && (
        <dialog id="my_modal_4" className="modal modal-open">
          <div className="modal-box w-11/12 max-w-2xl">
            <h3 className="font-bold text-lg">API key</h3>
            <div className="py-4 flex text-xs">
              Use this key in your application by passing it with the
              <pre className="bg-gray-200  p-1 mx-2 rounded-lg overflow-x-auto">
                <code><p >key=API_KEY</p></code>
              </pre>
              parameter.</div>
            <label className="input input-bordered flex items-center gap-2">
              <input type="text" className="grow" placeholder="Search" readOnly value={getKey} />
              <svg
                onClick={() => copyKey(getKey)}
                xmlns="http://www.w3.org/2000/svg"
                fill="none" viewBox="0 0 24 24"
                strokeWidth={1.5} stroke="currentColor" className="size-6 cursor-pointer">
                <path strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
              </svg>
            </label>
            <p className="flex text-xs  mt-2">
              <svg className="size-8 cursor-pointer  mr-3"
                viewBox="0 0 18 18"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.3 15.2c.7 0 1-.5.7-1.2L9.9 3.4c-.4-.7-1-.7-1.4 0L2.4 14c-.4.7-.1 1.2.7 1.2h12.2zm-5.4-2.1H8.5v-1.4h1.4v1.4zm0-2.7H8.5V7.7h1.4v2.7z"
                  fill="var(--cm-sys-color-status-warning, #DC6D00)"
                />
              </svg>
              This key is unrestricted. To prevent unauthorized use, we recommend restricting where and for which APIs it can be used. Edit API key to add restrictions. Learn more </p>
            <div className="modal-action">
              <form method="dialog">
                <button onClick={closeModal}>Close</button>
              </form>
            </div>
          </div>
        </dialog>
      )}
      {isOpenDialog && (
        <dialog id="my_modal_4" className="modal modal-open">
          <div className="modal-box w-11/12 max-w">
            <div className="flex justify-between">
              <h3 className="font-bold text-2xl ">Creating API key...</h3>
              <span className="loading loading-infinity loading-lg"></span>
            </div>
          </div>
        </dialog>
      )}
    </main>
  )
}

