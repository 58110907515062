class tinydb {
    // Encode data
    encodeData(data) { 
        return btoa(btoa( btoa(btoa(unescape(encodeURIComponent(data))) )) );
    }

    // Decode data
    decodeData(encodedData) { 
        // Check if the string appears to be Base64-encoded
        const isBase64 = /^[A-Za-z0-9+/]*={0,2}$/.test(encodedData);
        
        // If it appears to be Base64-encoded, attempt decoding
        if (isBase64) {
            try {
                // Decode the Base64-encoded string
                const decodedData = decodeURIComponent(escape(atob(atob(atob(atob(encodedData))))));
                return decodedData;
            } catch (error) {
                // If decoding fails, return the original encoded data
                // console.error("Failed to decode data:", error);
                return encodedData;
            }
        } else {
            // If it doesn't appear to be Base64-encoded, return the original data
            // console.error("Data appears to be improperly encoded:", encodedData);
            return encodedData;
        }
    }

    // Get data from localStorage
    get(key) {
        const encodedData = localStorage.getItem(key);
        if (encodedData == null) {
            return false;
        } else {
            return this.decodeData(encodedData);
        }
    }

    // Set data to localStorage
    set(key, value) {
        const encodedValue = this.encodeData(value); 
        localStorage.setItem( key, encodedValue );
        return true;
    }

    // Remove data from localStorage
    remove(key) {
        localStorage.removeItem(key);
        return true;
    }
    removeAll() {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i); // Get the key at index i
            localStorage.removeItem(key);    // Remove the item with the current key
        }
        return true;
    }
}

export default tinydb;
