class holder {
    host() {  
        return  process.env.NODE_ENV === "development"
            ? "http://localhost/Projects/sandbox.valoxity.com/"
            : "https://sandbox.valoxity.com/"
    }
    apiPoint() {
        return 'api/endpoint';
    }

}
export default holder;