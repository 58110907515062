
import { toast } from 'react-toastify';
class Toast { 
    showToastSuccess = ({ message }) => {
        toast.success(message, {
            position: 'top-center',
            autoClose: 2000 // milliseconds
        });
    };
    showToastError = ({ message }) => {
        toast.error(message, {
            position: 'top-center',
            autoClose: 2000 // milliseconds
        });
    };
    showToastInfo = ({ message }) => {
        toast.info(message, {
            position: 'top-center',
            autoClose: 2000 // milliseconds
        });
    };

}

export default Toast;

