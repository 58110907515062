import React, { createContext, useContext, ReactNode } from "react";
import https from "./utilitys/https"; 
import toast from "./utilitys/toast";
import cookie from "./utilitys/cookie"; 
import holder from "./utilitys/holder";
import tinydb from "./utilitys/tinydb";  

// Define the type for the context value
interface AppContextType {
    cookie: cookie;
    toast: toast;
    https: https;
    holder: holder;
    tinydb: tinydb;
}

// Create the context with the default value of the correct type
const AppContext = createContext<AppContextType | undefined>(undefined);

interface AppProviderProps {
    children: ReactNode;
}
 
const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
    return (
        <AppContext.Provider 
            value={{
                cookie: new cookie(),
                toast: new toast(),
                https: new https(new holder().host() + new holder().apiPoint()),
                holder: new holder(),
                tinydb: new tinydb()
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

const useGlobalContext = (): AppContextType => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error("useGlobalContext must be used within an AppProvider");
    }
    return context;
};

export { AppContext, AppProvider, useGlobalContext };
