const Inputs256 = ({type,placeholder,get,set,readOnly}:any) =>{
    return (<div>
        <input
        readOnly={readOnly}
          onChange={(e) => set(e.target.value)}
          value={get}
          type={type}
          placeholder={placeholder}
          className="w-full py-3 px-6 ring-1 ring-gray-300 rounded-xl placeholder-gray-600 bg-transparent transition disabled:ring-gray-200 disabled:bg-gray-100 disabled:placeholder-gray-400 invalid:ring-red-400 focus:invalid:outline-none"
        />
      </div>)
}

export {
  Inputs256
}